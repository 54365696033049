export default {
  namespaced: true,
  state: {
    isVisible: false,
    component: null,
    props: {},
    listeners: {},
  },
  mutations: {
    SET_MODAL(state, { component, props, listeners }) {
      state.isVisible = true;
      state.component = component;
      state.props = props;
      state.listeners = listeners;
    },
    HIDE_MODAL(state) {
      state.isVisible = false;
      state.component = null;
      state.props = {};
      state.listeners = {};
    },
  },
  actions: {
    show({ commit }, { component, props, listeners }) {
      commit("SET_MODAL", { component, props, listeners });
    },
    hide({ commit }) {
      commit("HIDE_MODAL");
    },
  },
};
