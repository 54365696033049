import axios from "axios";
import { API_CONFIG } from "./apiConfig";
import {
  createRequestInterceptor,
  createResponseInterceptor,
} from "./modules/interceptors";

export const createApi = (configKey) => {
  const config = API_CONFIG[configKey];
  if (!config) {
    throw new Error(`Invalid API configuration key: ${configKey}`);
  }

  const instance = axios.create({
    baseURL: config.baseURL,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    (config) => {
      config.requiresAuth = configKey !== "PUBLIC";
      return createRequestInterceptor(config.requiresAuth)(config);
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    createResponseInterceptor(configKey === "PUBLIC").success,
    createResponseInterceptor(configKey === "PUBLIC").error
  );

  return instance;
};

export const api = createApi("EMPLOYEES");
export const publicApi = createApi("PUBLIC");
