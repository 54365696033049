import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const tagsApi = {
  getAllVenueTags: () => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/tag/${currentVenueId}`);
  },
  editTag: (id, data) => api.patch(`tag/${id}`, data),
  createTag: (data) => {
    return api.post(`tag`, data);
  },
  deleteTag: (id) => api.delete(`tag/${id}`),
};

// Dodajemy funkcję pomocniczą do logowania
const logApiCall = (functionName, ...args) => {
  console.log(`Tags API Call: ${functionName}`, ...args);
};

// Opakowujemy każdą funkcję w logowanie
Object.keys(tagsApi).forEach((key) => {
  const originalFunction = tagsApi[key];
  tagsApi[key] = (...args) => {
    logApiCall(key, ...args);
    return originalFunction(...args);
  };
});
