import { api } from "../apiFactory";
import store from "@/store";

export const trainersApi = {
  addTrainerProfile: (data) => api.post(`trainer_venue_profile`, data),
  editTrainerProfile: (id, data) =>
    api.patch(`trainer_venue_profile/${id}`, data),
  getTrainerProfile: (userId) =>
    api.get(`user/trainer_venue_profile/${userId}`),
  deleteTrainerProfile: (id) => api.delete(`trainer_venue_profile/${id}`),
  getActiveTrainersFromVenue: () => {
    const currentVenueId = store.getters["employees/currentVenueId"];
    return api.get(`trainer_venue_profile`, {
      params: { venue_id: currentVenueId },
    });
  },
};
