import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const seasonsApi = {
  getAllVenueSeasons: () => {
    let currentVenueId = getCurrentVenueId();
    return api.get(`venue/season/${currentVenueId}`);
  },
  addNewSeason: (data) => api.post(`season`, data),
  createPriceGroupAssignment: (data) =>
    api.post(`season/price_group_assignment`, data),
  editPriceGroupAssignment: (id, data) =>
    api.put(`season/price_group_assignment/${id}`, data),
  editSingleSeason: (id, data) => api.put(`season/${id}`, data),
  deleteSeason: (id) => api.delete(`season/${id}`),
  deletePriceGroupAssignment: (id) =>
    api.delete(`season/price_group_assignment/${id}`),
};

// Dodajemy funkcję pomocniczą do logowania
const logApiCall = (functionName, ...args) => {
  console.log(`Seasons API Call: ${functionName}`, ...args);
};

// Opakowujemy każdą funkcję w logowanie
Object.keys(seasonsApi).forEach((key) => {
  const originalFunction = seasonsApi[key];
  seasonsApi[key] = (...args) => {
    logApiCall(key, ...args);
    return originalFunction(...args);
  };
});

export default seasonsApi;
