import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const sportObjectsApi = {
  getAllSportsFromObject: () => api.get(`app/sport_type`),
  getAllSportObjects: () => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/sport_object/${currentVenueId}`);
  },
  createSportObject: (data) => {
    return api.post(`venue_sport_object`, data);
  },
  updateSportObject: (id, data) => api.patch(`venue_sport_object/${id}`, data),
  deleteSportObject: (id) => api.delete(`venue_sport_object/${id}`),
};
