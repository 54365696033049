import { createStore } from "vuex";
import employees from "./modules/employees";
import cloudMessage from "./modules/cloudMessage";
import app from "./modules/app";
import modal from "./modules/modal";

const store = createStore({
  modules: {
    employees,
    cloudMessage,
    app,
    modal,
  },
});

export default store;
