import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const venueApi = {
  getAppPaymentMethods: () => {
    return api.get(`app/payment_methods`);
  },
  getCurrentVenueData: () => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/${currentVenueId}`);
  },
  updateVenue: (data) => {
    const currentVenueId = getCurrentVenueId();
    return api.patch(`venue/${currentVenueId}`, data);
  },
  getReservations: (data) => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/reservation/${currentVenueId}`, { params: data });
  },
  getGroupClasses: (data) => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/venue_group_classes/${currentVenueId}`, {
      params: data,
    });
  },
  updateClasses: (id, data) => api.patch(`venue_group_classes/${id}`, data),
  deleteClasses: (id, options = {}) =>
    api.delete(`venue_group_classes/${id}`, { params: options }),
  getSingleClasses: (classesId) => api.get(`venue_group_classes/${classesId}`),
};
