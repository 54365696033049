import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const pricingApi = {
  getPriceListGroups: () => {
    let currentVenueId = getCurrentVenueId();
    return api.get(`venue/price_list_group/${currentVenueId}`);
  },
  createPriceListGroup: (data) => api.post(`price_list_group`, data),
  editPriceListGroup: (id, data) => api.put(`price_list_group/${id}`, data),
  deletePriceListGroup: (id) => api.delete(`price_list_group/${id}`),
  createSinglePriceList: (data) => api.post(`price_list`, data),
  editSinglePriceList: (id, data) => api.patch(`price_list/${id}`, data),
  deleteSinglePriceList: (id) => api.delete(`price_list/${id}`),
  getPriceListDetails: (id) => api.get(`price_list_group/${id}`),
  getSinglePriceList: (id) => api.get(`price_list/${id}`),
};
