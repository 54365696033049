<template>
  <div class="flex h-screen bg-off-white">
    <!-- Sidebar -->
    <div
      class="navbar-sidebar bg-off-white flex flex-col justify-between transition-all duration-300 border-r border-light-grey"
      :class="{ 'w-20': isCollapsed, 'w-64': !isCollapsed }"
    >
      <div>
        <!-- Logo -->
        <div
          class="px-4 py-5 flex items-center"
          :class="{
            'justify-between': !isCollapsed,
            'justify-center': isCollapsed,
          }"
        >
          <img
            v-if="!isCollapsed"
            src="/images/Playmore-Logo-Default-version-SVG.svg"
            alt="Playmore Logo"
            class="h-8"
          />
          <button
            @click="toggleSidebar"
            class="text-cod-grey hover:text-dynamic-orange"
          >
            <Bars3Icon class="w-6 h-6" />
          </button>
        </div>
        <!-- Venue Selection -->
        <div v-if="!isCollapsed" class="px-4 py-2">
          <select
            v-model="selectedVenue"
            @change="selectVenue"
            class="w-full p-2 text-sm border border-light-grey rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-dynamic-orange focus:border-dynamic-orange"
          >
            <option value="" disabled>Wybierz ośrodek</option>
            <option
              v-for="venue in availableVenues"
              :key="venue.id"
              :value="venue.id"
            >
              {{ venue.name }}
            </option>
          </select>
        </div>
        <!-- Menu Items -->
        <nav class="mt-5 px-2">
          <router-link
            v-for="(item, index) in menuItems"
            :key="index"
            :to="item.path"
            class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md transition ease-in-out duration-150"
            :class="[
              $route.path === item.path
                ? 'bg-dynamic-orange text-white'
                : 'text-cod-grey hover:text-dynamic-orange hover:bg-light-grey',
              { 'justify-center': isCollapsed, 'mt-1': index > 0 },
            ]"
          >
            <component
              :is="item.icon"
              :class="[
                'w-6 h-6',
                { 'mr-4': !isCollapsed },
                $route.path === item.path
                  ? 'text-white'
                  : 'text-cod-grey group-hover:text-dynamic-orange',
              ]"
            />
            <span v-if="!isCollapsed">{{ item.name }}</span>
          </router-link>
        </nav>
      </div>
      <!-- User Menu -->
      <div class="border-t border-light-grey p-4">
        <div
          :class="{
            'flex flex-col items-center': isCollapsed,
            'flex items-center justify-between': !isCollapsed,
          }"
        >
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <div
                class="w-10 h-10 rounded-full bg-dynamic-orange flex items-center justify-center text-off-white font-semibold text-lg"
              >
                {{ userInitials }}
              </div>
            </div>
            <div v-if="!isCollapsed" class="ml-3">
              <p class="text-sm font-medium text-cod-grey">
                {{ userName }}
              </p>
              <p class="text-xs text-gray-500">
                {{ userEmail }}
              </p>
            </div>
          </div>
          <button
            @click="logout"
            class="text-gray-400 hover:text-dynamic-orange mt-2"
            :class="{ 'mt-2': isCollapsed }"
          >
            <ArrowRightOnRectangleIcon class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
    <!-- Main Content -->
    <div class="flex-1 overflow-auto bg-off-white main-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  Bars3Icon,
  CalendarIcon,
  UserGroupIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "Navbar",
  components: {
    Bars3Icon,
    CalendarIcon,
    UserGroupIcon,
    Cog6ToothIcon,
    ArrowRightOnRectangleIcon,
    UserIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isCollapsed = ref(false);
    const selectedVenue = ref("");

    const toggleSidebar = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const logout = async () => {
      await store.dispatch("employees/logout");
      router.push("/login");
    };

    const selectVenue = async () => {
      if (selectedVenue.value) {
        try {
          await store.dispatch(
            "employees/setEmployeeVenue",
            selectedVenue.value
          );
          console.log("Venue changed successfully");
          store.dispatch("cloudMessage/showMessage", {
            message: "Ośrodek został zmieniony pomyślnie",
            type: "success",
          });
          router.go();
        } catch (error) {
          console.error("Failed to set venue:", error);
          store.dispatch("cloudMessage/showMessage", {
            message: "Nie udało się zmienić ośrodka. Spróbuj ponownie.",
            type: "error",
          });
        }
      }
    };

    const userName = computed(() => {
      const user = store.getters["employees/currentUser"];
      return user ? `${user.first_name} ${user.last_name}` : "";
    });

    const userEmail = computed(() => {
      const user = store.getters["employees/currentUser"];
      return user ? user.email : "";
    });

    const userInitials = computed(() => {
      const user = store.getters["employees/currentUser"];
      if (user) {
        return `${user.first_name.charAt(0)}${user.last_name.charAt(
          0
        )}`.toUpperCase();
      }
      return "";
    });

    const availableVenues = computed(() => {
      const venues = store.getters["employees/availableVenues"];
      return venues.map((venue) => ({
        ...venue,
        name: `${venue.name} (#${venue.id})`,
      }));
    });

    const currentVenue = computed(
      () => store.getters["employees/currentVenueId"]
    );

    // Set the initial selected venue
    selectedVenue.value = currentVenue.value;

    // Watch for changes in the currentVenue and update selectedVenue
    watch(currentVenue, (newVenue) => {
      selectedVenue.value = newVenue;
    });

    const menuItems = [
      { name: "Kalendarz", path: "/", icon: CalendarIcon },
      { name: "Klienci", path: "/clients", icon: UserIcon },
      { name: "Pracownicy", path: "/employees", icon: UserGroupIcon },
      { name: "Ustawienia", path: "/settings", icon: Cog6ToothIcon },
    ];

    return {
      isCollapsed,
      toggleSidebar,
      logout,
      userName,
      userEmail,
      userInitials,
      menuItems,
      availableVenues,
      selectedVenue,
      selectVenue,
    };
  },
};
</script>

<style scoped>
.navbar-sidebar {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
}

.main-content {
  margin-left: 10px;
}
</style>
