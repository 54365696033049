import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const carnetsApi = {
  getAllCarnetTypes: () => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/carnet_type/${currentVenueId}`);
  },
  addCarnetTypeToVenue: (data) => api.post(`venue_carnet_type`, data),
  getCarnetType: (id) => api.get(`venue_carnet_type/${id}`),
  editCarnetType: (id, data) => api.patch(`venue_carnet_type/${id}`, data),
  deleteCarnetType: (id) => api.delete(`venue_carnet_type/${id}`),
};
