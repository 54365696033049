<template>
  <div class="h-screen">
    <TopLoader />
    <Navbar v-if="shouldShowNavbar" />
    <div v-else class="h-full">
      <router-view></router-view>
    </div>
    <CloudMessage />
    <ModalContainer />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Navbar from "@/components/Navbar.vue";
import CloudMessage from "@/components/addons/cloudMessage.vue";
import TopLoader from "@/components/TopLoader.vue";
import ModalContainer from "@/components/ModalContainer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    CloudMessage,
    TopLoader,
    ModalContainer,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isAuthenticated = computed(
      () => store.getters["employees/isAuthenticated"]
    );
    const isLoginPage = computed(() => route.path === "/login");
    const hasSelectedVenue = computed(
      () => store.getters["employees/hasSelectedVenue"]
    );

    const shouldShowNavbar = computed(
      () =>
        isAuthenticated.value && !isLoginPage.value && hasSelectedVenue.value
    );

    // Dodajemy logikę do kontrolowania stanu ładowania
    const startLoading = () => {
      store.commit("app/SET_LOADING", true);
    };

    const stopLoading = () => {
      store.commit("app/SET_LOADING", false);
    };

    onMounted(() => {
      router.beforeEach((to, from, next) => {
        startLoading();
        next();
      });

      router.afterEach(() => {
        stopLoading();
      });
    });

    onUnmounted(() => {
      // Usuwamy nasłuchiwanie zdarzeń routera, jeśli to konieczne
    });

    return {
      shouldShowNavbar,
    };
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
}
</style>
