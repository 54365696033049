import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const clientsApi = {
  getAllClients: (page = 1, perPage = 15) =>
    api.get("/user", { params: { page, per_page: perPage } }),
  getClientData: (userId) => api.get(`/user/${userId}`),
  updateClientData: (userId, data) => api.patch(`/user/${userId}`, data),
  addClient: (data) => api.post("/user", data),
  deleteClient: (userId) => api.delete(`/user/${userId}`),
  addCarnet: (data) => api.post("/user_venue_carnet", data),
  getUserReservation: (userId) => api.get(`/user/reservation/${userId}`),
  getUserCarnets: (userId) => api.get(`/user/user_venue_carnet/${userId}`),
  deleteUserCarnet: (carnetId) => api.delete(`/user_venue_carnet/${carnetId}`),
  editUserCarnet: (carnetId, data) =>
    api.patch(`/user_venue_carnet/${carnetId}`, data),
  getCarnetPayments: (carnetId) =>
    api.get(`/user_venue_carnet/payment/${carnetId}`),
  deleteCarnetPayment: (paymentId) =>
    api.delete(`/user_venue_carnet/payment/${paymentId}`),
  addCarnetPayment: (data) => api.post("/user_venue_carnet/payment", data),
  searchClient: (userData) => {
    let currentVenueId = getCurrentVenueId();
    return api.get(`/search/user_venue_profile/${currentVenueId}`, {
      params: {
        query: userData,
      },
    });
  },
};
