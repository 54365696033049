import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const reservationsApi = {
  getReservations: (data) => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/reservation/${currentVenueId}`, { params: data });
  },
  createReservation: (data) => {
    return api.post(`reservation`, data);
  },
  updateReservation: (id, data) => api.patch(`reservation/${id}`, data),
  getReservationDetails: (reservationId) =>
    api.get(`reservation/${reservationId}`),
  deleteReservation: (id, data) =>
    api.delete(`reservation/${id}`, { params: data }),
  getReservationPayments: (reservationId) =>
    api.get(`reservation/payment/${reservationId}`),
  deleteReservationPayment: (paymentId) =>
    api.delete(`reservation/payment/${paymentId}`),
  addReservationPayment: (data) => {
    return api.post(`reservation/payment`, data);
  },
  addGroupClasses: (data) => {
    return api.post(`venue_group_classes`, data);
  },
  getReservationLog: (reservationId) => {
    return api.get(`/reservation/log/${reservationId}`);
  },
  scanDiscountCard: (reservationId) => {
    return api.post(`/reservation/scan_card/${reservationId}`);
  },
  getAvailablePriceLists: (beginDate, endDate, venueSportObjectId, userId) => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`/venue/sport_object/price_list/${currentVenueId}`, {
      params: {
        begin_date: beginDate,
        end_date: endDate,
        venue_sport_object_id: venueSportObjectId,
        user_id: userId,
      },
    });
  },
};
