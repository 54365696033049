<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
  >
    <div class="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
      <component
        :is="modalComponent"
        v-bind="modalProps"
        v-on="modalListeners"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "ModalContainer",
  setup() {
    const store = useStore();

    const isVisible = computed(() => store.state.modal.isVisible);
    const modalComponent = computed(() => store.state.modal.component);
    const modalProps = computed(() => store.state.modal.props);
    const modalListeners = computed(() => store.state.modal.listeners);

    return {
      isVisible,
      modalComponent,
      modalProps,
      modalListeners,
    };
  },
};
</script>
