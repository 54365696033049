import store from "@/store";
import { getCurrentVenueId } from "../apiConfig";

export const createRequestInterceptor =
  (requiresAuth = false) =>
  (config) => {
    if (requiresAuth) {
      const token = store.getters["employees/authToken"];
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        const localStorageToken = localStorage.getItem("authToken");
        if (localStorageToken) {
          config.headers["Authorization"] = `Bearer ${localStorageToken}`;
        }
      }
    }

    const currentVenueId = getCurrentVenueId();

    if (currentVenueId && config.url !== "employee/set_venue") {
      const venueIdNumber = parseInt(currentVenueId, 10);
      if (!isNaN(venueIdNumber)) {
        if (config.method.toLowerCase() === "get") {
          config.params = { ...config.params, venue_id: venueIdNumber };
        } else {
          config.data = { ...config.data, venue_id: venueIdNumber };
        }
      } else {
        console.error("Invalid venue_id:", currentVenueId);
      }
    }

    console.info(
      `API Call: ${config.method.toUpperCase()} ${config.url}`,
      config.params,
      config.data,
      config.headers
    );
    return config;
  };

export const createResponseInterceptor = (isPublic = false) => ({
  success: (response) => {
    console.info(
      `${isPublic ? "PUBLIC " : ""}API Response: ${response.status} ${
        response.statusText
      }`,
      response.data
    );
    return response;
  },
  error: (error) => {
    console.error(
      `${isPublic ? "PUBLIC " : ""}API Response Error:`,
      error.response ? error.response.data : error.message
    );

    if (error.response && error.response.status === 401) {
      console.log("Token expired, logging out...");
      store.dispatch("employees/handleAuthError");
    }

    return Promise.reject(error);
  },
});
