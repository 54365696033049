import store from "@/store";

export const API_CONFIG = {
  EMPLOYEES: {
    baseURL: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_EMPLOYEES_ENDPOINT}`,
    requiresAuth: true,
  },
  PUBLIC: {
    baseURL: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_PUBLIC_ENDPOINT}`,
    requiresAuth: false,
  },
};

export const getCurrentVenueId = () => {
  const venueId = store.getters["employees/currentVenueId"];
  return venueId;
};
